<template>
  <div class="pageMain">
    <!-- 企业备案 -->
    <div class="content">
      <!-- 左边列表 -->
      <CustomTables
        :data="tableData"
        :total="total"
        class="tableHear"
        :headerCellStyle="headerCellStyle"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChanges="handleCurrentChangesProject"
        :titleIcon="true"
      >
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            :iconShow="true"
            :infoShow="true"
            info="项目列表"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item class="formItem">
                <el-input
                  @input="change"
                  v-model="searchForm.projName"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>

        <el-table-column type="index" label="排序" width="50" align="center">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" align="center">
        </el-table-column>
        <el-table-column
          prop="deviceCount"
          label="总数(台)"
          align="center"
          width="80"
        >
        </el-table-column>
      </CustomTables>
      <div class="tableBox">
        <CustomTables
          :data="tableData2"
          :headerCellStyle="headerCellStyle"
          height="250"
          :total="total2"
          title="项目设备列表"
          @selectionChange="handleSelectionChange"
          class="tables"
          @handleCurrentChanges="handleCurrentChanges"
          @handleCurrentChange="handleCurrentChange2"
          @handleSizeChange="handleSizeChange2"
        >
          <template slot="tabs">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane name="0">
                <span slot="label">
                  塔机 ({{ count.towerCount ? count.towerCount : 0 }})</span
                >
              </el-tab-pane>
              <el-tab-pane name="1">
                <span slot="label">
                  升降机({{
                    count.elevatorCount ? count.elevatorCount : 0
                  }})</span
                >
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label" name="2">
                  龙门吊 ({{
                    count.gantrycraneCount ? count.gantrycraneCount : 0
                  }})</span
                >
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label" name="3">
                  架桥机 ({{ count.bridgeCount ? count.bridgeCount : 0 }})</span
                >
              </el-tab-pane>
              <el-tab-pane>
                <span slot="label" name="5">
                  挂篮 ({{ count.basketCount ? count.basketCount : 0 }})</span
                >
              </el-tab-pane>
            </el-tabs>
          </template>
          <el-table-column type="selection" align="center" width="55">
          </el-table-column>

          <el-table-column prop="deviceType" label="设备类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.deviceType == 0">塔吊</span>
              <span v-if="scope.row.deviceType == 1">升降机</span>
              <span v-if="scope.row.deviceType == 2">龙门吊</span>
              <span v-if="scope.row.deviceType == 3">架桥机</span>
              <span v-if="scope.row.deviceType == 4">爬模</span>
              <span v-if="scope.row.deviceType == 5">挂篮</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="date" label="起重设备编号"  align="center"> </el-table-column> -->
          <el-table-column
            prop="deviceImei"
            label="监控主机IMEI"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="deviceVersion" label="终端版本" align="center">
          </el-table-column>
          <!-- <el-table-column prop="date" label="违章次数"  align="center"> </el-table-column> -->
        </CustomTables>
        <CustomTables
          :data="tableData3"
          :headerCellStyle="headerCellStyle"
          height="250"
          :total="total3"
          title="工作循环"
          @selectionChange="handleSelectionChanges"
          @handleCurrentChange="handleCurrentChange3"
          @handleSizeChange="handleSizeChange3"
        >
          <template slot="tabs">
            <el-tabs v-model="activeName2" @tab-click="handleClick2">
              <el-tab-pane
                v-for="(tab, index) in tabs2"
                :key="tab.name"
                :label="tab.label"
                :name="tab.name"
              >
              </el-tab-pane>
            </el-tabs>
          </template>
          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间" align="center">
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" align="center">
          </el-table-column>
          <el-table-column prop="maxWeight" label="最大载重" align="center">
          </el-table-column>
          <el-table-column prop="startAngle" label="开始角度" align="center">
          </el-table-column>
          <el-table-column prop="endAngle" label="结束角度" align="center">
          </el-table-column>
          <!-- <el-table-column prop="name" label="最大角度"  align="center"> </el-table-column>
            <el-table-column prop="name" label="最小角度"  align="center"> </el-table-column>
            <el-table-column prop="name" label="开始幅度"  align="center"> </el-table-column>
            <el-table-column prop="name" label="结束幅度"  align="center"> </el-table-column> -->
          <el-table-column prop="maxTravel" label="最大幅度" align="center">
          </el-table-column>
          <el-table-column prop="minTravel" label="最小幅度" align="center">
          </el-table-column>
          <!-- <el-table-column prop="name" label="开始高度"  align="center"> </el-table-column>
            <el-table-column prop="name" label="结束高度"  align="center"> </el-table-column> -->
          <el-table-column prop="maxHeight" label="最大高度" align="center">
          </el-table-column>
          <el-table-column prop="minHeight" label="最小高度" align="center">
          </el-table-column>
          <el-table-column prop="maxWindSpeed" label="最大风速" align="center">
          </el-table-column>
          <el-table-column prop="maxMonent" label="最大力矩" align="center">
          </el-table-column>
          <!-- <el-table-column label="回放" align="center" width="100">
            <template slot-scope="scope">
              <span @click="detailItem(scope.row)" class="detail">查看</span>
            </template>
          </el-table-column> -->
        </CustomTables>
      </div>
    </div>
  </div>
</template>

<script>
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import monitor from "../../../api/modules/monitor";
export default {
  components: { CustomTables, SearchHead, SearchLeft },
  name: "monitorAlarm",
  data() {
    return {
      activeName: "0",
      tableData: [],
      tableData2: [],
      tableData3: [],
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      searchForm2: {
        currPage: 1,
        pageSize: 10,
      },
      searchForm3: {
        currPage: 1,
        pageSize: 10,
      },
      activeName: "0",
      activeName2: "1",
      tabs: [
        {
          label: "塔机",
          name: "tower",
        },
        {
          label: "升降机",
          name: "towers",
        },
      ],
      tabs2: [
        {
          label: "今天",
          name: "1",
        },
        {
          label: "本周",
          name: "2",
        },
        {
          label: "上周",
          name: "3",
        },
        {
          label: "本月",
          name: "4",
        },
      ],
      total: 0,
      total2: 0,
      total3: 0,
      count: "",
      deviceNo: "",
      projId: "",
      deviceImei: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleCurrentChangesProject(row) {
      this.activeName2='1'
      this.ProjectDeviceCount(row.projId);
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchFormpageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getList();
    },
    handleCurrentChange2(pageIndex) {
      console.log("pageIndex", pageIndex);

      this.searchForm2.currPage = pageIndex;
      this.getProjectDeviceListByPageworks(this.projId, this.activeName);
    },
    handleSizeChange2(pageSize) {
      this.searchForm2.pageSize = pageSize;
      this.searchForm2.currPage = 1;
      this.getProjectDeviceListByPageworks(this.projId, this.activeName);
    },
    handleCurrentChange3(pageIndex) {
      this.searchForm3.currPage = pageIndex;
      this.getList();
    },
    handleSizeChange3(pageSize) {
      this.searchForm3.pageSize = pageSize;
      this.searchForm3.currPage = 1;
      this.getList();
    },
    getList() {
      monitor.getProjectListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        if (this.tableData.length > 0) {
          this.projId = this.tableData[0].projId;
          this.ProjectDeviceCount(this.tableData[0].projId);
        } else {
          // this.ProjectDeviceCount()
        }
      });
    },
    change(e) {
      console.log("e", e);
      this.searchForm.projName = e;
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList(this.searchForm);
    },
    ProjectDeviceCount(projId) {
      this.activeName = "0";
      monitor.getProjectDeviceCountllegal(projId).then((res) => {
        console.log("resx", res);
        this.count = res.data;
        this.projId = projId;
        this.getProjectDeviceListByPageworks(projId, 0);
      });
    },
    getProjectDeviceListByPageworks(projId, deviceType) {
      let params = {
        currPage: this.searchForm2.currPage,
        pageSize: this.searchForm2.pageSize,
        projId: projId,
        deviceType: deviceType,
      };

      monitor.getProjectDeviceListByPagework(params).then((res) => {
        this.total2 = res.data.total;
        this.tableData2 = res.data.records;
        if (this.tableData2.length > 0) {
          this.deviceImei = this.tableData2[0].deviceImei;
          this.getWorkListByPages(this.tableData2[0].deviceImei, 1);
        }
      });
    },
    getWorkListByPages(deviceImei, workTime) {
      let params = {
        currPage: this.searchForm3.currPage,
        pageSize: this.searchForm3.pageSize,
        deviceImei: deviceImei,
        workTime: workTime,
      };
      monitor.getWorkListByPage(params).then((res) => {
        this.total3 = res.data.total;
        this.tableData3 = res.data.records;
      });
    },
    search() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getList(this.searchForm);
    },
    reset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getList();
    },
    handleClick(tab, event) {
      this.searchForm2.currPage = 1;
      this.getProjectDeviceListByPageworks(this.projId, tab.paneName);
    },
    handleClick2(tab, event) {
      this.searchForm3.currPage = 1;
      console.log(tab, event);
      this.getWorkListByPages(this.deviceImei, tab.paneName);
    },
    detailItem() {},
    handleCurrentChanges(val) {
      this.activeName2='1'
      this.deviceImei = val.deviceImei;
      this.getWorkListByPages(this.deviceImei, this.activeName2);

      // this.getAlarmByPages(val.deviceNo)
      // this.deviceNo=val.deviceNo
    },
    handleSelectionChange() {},
    handleSelectionChanges() {},
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
}
.tableBox {
  // margin-left: 10px;
  width: 72%;
}
.tableHear {
  width: 25%;
  overflow: auto
  // background:#e4ebf1
}
.title {
  margin-bottom: 10px;
color: #000;
}

.formItem{
  margin-bottom: 0px;
}
.tables{
  margin-bottom: 10px;
}
</style>
